import { useRouter as useNextRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { UrlObject } from "url";
import { Json } from "../types";
import { DefaultQueryType } from "../types/query";
import { omit } from "../utils/objects";
import { browser } from "../utils/platform";

export function flatten<T extends {} = Record<string, string | string[]>>(obj: Json, prefix = ""): T | undefined {
  if (!obj) return;

  return Object.keys(obj).reduce((acc, k) => {
    if (k === "" || obj[k] === undefined || obj[k] === null) return acc;

    const pre = prefix.length ? prefix + "." : "";

    if (typeof obj[k] === "object") Object.assign<T, T>(acc, flatten(obj[k] as {}, pre + k) as T);
    else acc[pre + k] = obj[k];

    return acc;
  }, {} as T);
}

export function unflatten<T = Json>(obj?: Record<string, string | string[]>): T {
  if (!obj) return {} as T;

  const simpleObj = Object.keys(obj).reduce((acc, k) => {
    acc[k] = Array.isArray(obj[k]) && obj[k].length === 1 ? obj[k][0] : obj[k];
    return acc;
  }, {});

  const result: Json = {};

  for (let i in simpleObj) {
    var keys = i.split(".");
    keys.reduce((acc, k, j) => {
      return acc[k] || (acc[k] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? obj[i] : {}) : []);
    }, result);
  }

  return result as any;
}

interface Group {
  repeat: boolean;
  optional: boolean;
}

// this isn't importing the escape-string-regex module
// to reduce bytes
function escapeRegex(str: string) {
  return str.replace(/[|\\{}()[\]^$+*?.-]/g, "\\$&");
}

function parseParameter(param: string) {
  const optional = param.startsWith("[") && param.endsWith("]");
  if (optional) {
    param = param.slice(1, -1);
  }
  const repeat = param.startsWith("...");
  if (repeat) {
    param = param.slice(3);
  }
  return { key: param, repeat, optional };
}

export function getRouteParams(normalizedRoute: string): {
  [groupName: string]: Group;
} {
  const segments = (normalizedRoute.replace(/\/$/, "") || "/").slice(1).split("/");
  const groups: { [groupName: string]: Group } = {};

  segments.map((segment) => {
    if (segment.startsWith("[") && segment.endsWith("]")) {
      const { key, optional, repeat } = parseParameter(segment.slice(1, -1));
      groups[key] = { repeat, optional };
    } else {
      return `/${escapeRegex(segment)}`;
    }
  });

  return groups;
}

export const stripQueryFromPathname = (pathname: string): { pathname: string; query: Record<string, string> } => {
  if (pathname.includes("://")) throw new Error("Invalid pathname provided.");

  const pathnameSplit = pathname.split("?");
  const query: Record<string, string> = {};

  if (!!pathnameSplit[1]) {
    const searchParams = new URLSearchParams(pathnameSplit[1]);
    searchParams.forEach((value, key) => {
      query[key] = value;
    });
  }

  return { pathname: pathnameSplit[0], query };
}

/**
 * The purpose of this function is to strip any extra query that is included in the pathname
 * and add it to the query object. The next router will ignore the query object if the pathname contains
 * a query. For example: 
 * 
 * { pathname: "/path/page?id=someid", query: { type: "bananas" } }
 * 
 * Will lose the query "type=bananas" on route change.
 */
export const processUrlObject = (urlObject: UrlObject): UrlObject => {
  if (!urlObject.pathname || typeof urlObject.query === "string") return urlObject;

  const processed = stripQueryFromPathname(urlObject.pathname);

  return {
    ...urlObject,
    pathname: processed.pathname,
    query: {
      ...urlObject.query,
      ...processed.query,
    },
  };
};

export function useOurRouter<T extends DefaultQueryType>() {
  const router = useNextRouter();

  const params = useMemo(() => getRouteParams(router.pathname), [router.pathname]);
  const parsedQuery = useMemo(() => unflatten<Partial<T>>(router.query as any), [router.query]);

  const setQuery = useCallback(
    (values) => {
      const url = `${router.asPath.replace(/\?.*/, "")}${!!values ? `?${new URLSearchParams(flatten(values))}` : ""}`;
      return router.replace(url);
    },
    [router]
  );

  const push = useCallback(
    (url, as?: string, options?: Record<string, any>) => {
      if (!browser().isBrowser) {
        console.warn(`tried calling push to ${typeof url !== "string" ? url.pathname : url} durring SSR`);
        return Promise.resolve(false);
      }

      const query = typeof url !== "string" ? flatten(omit(Object.keys(params), url.query || {})) : {};

      if (typeof url !== "string" && !url.pathname) {
        return setQuery(query);
      }

      return router.push(
        typeof url === "string"
          ? url
          : processUrlObject({
              pathname: url.pathname,
              query,
            }),
        as,
        options
      );
    },
    [params, router, setQuery]
  );

  const replace = useCallback(
    (url, as?: string, options?: Record<string, any>) => {
      if (!browser().isBrowser) {
        console.warn(`tried calling replace to ${typeof url !== "string" ? url.pathname : url} durring SSR`);
        return Promise.resolve(false);
      }

      const query = typeof url !== "string" ? flatten<Partial<T>>(omit(Object.keys(params), url.query || {})) : {};

      if (typeof url !== "string" && !url.pathname) {
        return setQuery(query);
      }

      return router.replace(
        typeof url === "string"
          ? url
          : processUrlObject({
              pathname: url.pathname,
              query,
            }),
        as,
        options
      );
    },
    [params, router, setQuery]
  );

  const wrapped = useMemo(
    () => ({
      ...router,
      query: parsedQuery, // FIXME (IW): This shouldn't have replaced router.query in the first place
      search: router.query,
      params,
      push,
      replace,
    }),
    [params, parsedQuery, push, replace, router]
  );

  return wrapped;
}
