// eslint-disable-next-line no-restricted-imports
import { OptionsObject, ProviderContext, SnackbarKey, useSnackbar } from "notistack";
import { isValidElement, ReactElement, ReactNode, Ref, useCallback } from "react";
import { Snackbar, SnackbarOptionsProps } from "../components/Snackbar";

export type NotificationCloseHandler = () => void;

export type UseNotificationsSendSnackbarOptions =
  | SnackbarOptionsProps
  | ReactNode
  | ReactElement<{ ref: Ref<HTMLElement> }>
  | ((close: NotificationCloseHandler) => ReactElement<{ ref: Ref<HTMLElement> }>);

export type UseNotificationsReturnType = {
  sendNotification: (
    snackbarOptions: UseNotificationsSendSnackbarOptions,
    notistackOptions?: OptionsObject
  ) => NotificationCloseHandler;
};

export const useNotifications = (): UseNotificationsReturnType => {
  // useNotifications returns undefined in SSR
  const { enqueueSnackbar, closeSnackbar } = (useSnackbar?.() as ProviderContext | undefined) || {};

  const sendNotification = useCallback<UseNotificationsReturnType["sendNotification"]>(
    (snackbarOptions, notistackOptions) => {
      let id: SnackbarKey;
      const handleClose = () => closeSnackbar?.(id);

      id =
        enqueueSnackbar?.("", {
          content: (() => {
            if (typeof snackbarOptions === "function") return snackbarOptions(handleClose);
            else if (isValidElement(snackbarOptions)) return snackbarOptions;
            else if (typeof snackbarOptions === "object")
              return <Snackbar onClose={handleClose} {...(snackbarOptions as SnackbarOptionsProps)} />;
            else return <Snackbar onClose={handleClose} message={snackbarOptions} />;
          })(),
          ...notistackOptions,
        }) || "";

      return handleClose;
    },
    [closeSnackbar, enqueueSnackbar]
  );

  return { sendNotification };
};
