import { useEffect, useState } from "react";
import { YYYYMMDD } from "src/reclaim-api/types";
import { KeysByValue } from "../types";

/**
 * Sort direction
 */
export type SortStateDirection = "asc" | "desc";

/**
 * The state of sorting
 */
export interface SortState<S extends string, T> {
  sortOrder: S[];
  items: T[];
  direction?: SortStateDirection;
}

/**
 * Update options for the updateSortState function
 */
export interface SortStateUpdateOptions<S extends string> {
  sortBy?: S;
  direction?: SortStateDirection;
}

/**
 * A comparator to feed into a sort function
 */
export type SortStateComparator<T> = (a: T, b: T) => number;

/**
 * A mapping of comparators to keys
 */
export type SortStateComparators<S extends string, T> = Record<S, SortStateComparator<T>>;

export type SortStateMultiComparator<T> = [T, T, SortStateComparator<T>];

export function sortMin<T>(comparator: SortStateComparator<T>, firstItem: T, ...rest: T[]): T;
export function sortMin<T>(comparator: SortStateComparator<T>, ...items: T[]): T | undefined;
export function sortMin<T>(comparator: SortStateComparator<T>, ...items: T[]): T | undefined {
  return items.sort(comparator)[0];
}

export function sortMax<T>(comparator: SortStateComparator<T>, firstItem: T, ...rest: T[]): T;
export function sortMax<T>(comparator: SortStateComparator<T>, ...items: T[]): T | undefined;
export function sortMax<T>(comparator: SortStateComparator<T>, ...items: T[]): T | undefined {
  const sorted = items.sort(comparator);
  return sorted[sorted.length - 1];
}

/**
 * Copies a SortState object, making new references for the object itself, as well as sortOrder and items.  Will NOT make copies of objects in items.
 * @param state The state to copy
 * @returns A new state object
 */
export const copySortState = <S extends string, T>({ sortOrder, items }: SortState<S, T>): SortState<S, T> => ({
  sortOrder: [...sortOrder],
  items: [...items],
});

/**
 * Returns a new SortState with items ordered consistantly with the internal values of sortOrder and direction, and according to the functions in comparators
 * @param state The current SortState
 * @param comparators A map of comparators for sorting
 * @param update The properties in SortState to update
 * @returns A new SortState with items sorted
 */
export function updateSortState<S extends string, T>(
  state: SortState<S, T>,
  comparators: SortStateComparators<S, T>,
  update?: SortStateUpdateOptions<S>
): SortState<S, T> {
  const { sortBy, direction = "asc" } = update || {};

  state = copySortState(state);
  state.direction = direction;
  const revMult = direction === "asc" ? 1 : -1;

  if (typeof sortBy === "string") {
    const index = state.sortOrder.indexOf(sortBy);
    if (index !== -1) {
      state.sortOrder.splice(index, 1);
      state.sortOrder.unshift(sortBy);
    }
  }

  function compare(a: T, b: T, sortByIndex = 0): number {
    const sortBy = state.sortOrder[sortByIndex];
    if (typeof sortBy !== "string") return 0;
    const result = comparators[sortBy](a, b) * revMult;
    if (!result) return compare(a, b, sortByIndex + 1);
    return result;
  }

  state.items.sort(compare);

  return state;
}

/**
 * Hook for maintaining a SortState in React components with items ordered consistantly with the internal values of sortOrder and direction, and according to the functions in comparators
 * @param state The current SortState
 * @param comparators A map of comparators for sorting
 * @param update The properties in SortState to update
 * @returns A new SortState with items sorted
 */
export function useSortState<S extends string, T>(
  state: SortState<S, T>,
  comparators: SortStateComparators<S, T>,
  update?: SortStateUpdateOptions<S>
): SortState<S, T> {
  const [internalState, setInternalState] = useState<SortState<S, T>>(state);

  useEffect(() => {
    setInternalState(updateSortState(state, comparators, update));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update?.sortBy, update?.direction, state.items, state.direction, state.sortOrder]);

  return internalState;
}

export function useSort<S extends string, T>(
  items: T[],
  initialSortOrder: S[],
  comparators: SortStateComparators<S, T>,
  initialDirection: SortStateDirection = "asc"
): [state: SortState<S, T>, setSortBy: (sortBy: S) => void, setDirection: (direction: SortStateDirection) => void] {
  const [sortBy, setSortBy] = useState(initialSortOrder[0]);
  const [direction, setDirection] = useState(initialDirection);

  const sortState = useSortState(
    {
      items,
      sortOrder: initialSortOrder,
    },
    comparators,
    {
      sortBy,
      direction,
    }
  );

  return [sortState, setSortBy, setDirection];
}

export const reverseComparator =
  <T>(comparator: SortStateComparator<T>): SortStateComparator<T> =>
  (a, b) =>
    -1 * comparator(a, b);

/**
 * Checks if array is sorted according to comparator.  O(n) compared to JS .sort which is O(n log n).
 * @param arr an array to check
 * @param comparator the comparator to use
 */
export function isSorted<T>(arr: T[], comparator: (a: T, b: T) => number): boolean {
  for (let i = 0; i < arr.length - 1; i++) if (comparator(arr[i], arr[i + 1]) > 0) return false;
  return true;
}

/**
 * comparator for number arrays
 */
export const numComparator: SortStateComparator<number | undefined> = (a = Infinity, b = Infinity) =>
  a === b ? 0 : a - b;
/**
 * comparator for string arrays
 */
export const alphaComparator: SortStateComparator<string | undefined> = (a = "", b = "") => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  // eslint-disable-next-line no-nested-ternary
  return a === b ? 0 : a > b ? 1 : -1;
};
/**
 * comparator for boolean arrays
 */
export const boolComparator: SortStateComparator<boolean | undefined> = (a = false, b = false) =>
  // eslint-disable-next-line no-nested-ternary
  a === b ? 0 : a && !b ? 1 : -1;
/**
 * comparator for date arrays
 */
export const dateComparator: SortStateComparator<Date | undefined | null> = (a, b) => {
  if (a?.getTime() === b?.getTime()) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return a.getTime() - b.getTime();
};

/**
 * comparator for date arrays
 */
export const yyyyMmDdComparator: SortStateComparator<YYYYMMDD | undefined | null> = (a, b) =>
  alphaComparator(a || undefined, b || undefined);

export function multiComparator<A>(comp: SortStateMultiComparator<A>): number;
export function multiComparator<A, B>(compA: SortStateMultiComparator<A>, compB: SortStateMultiComparator<B>): number;
export function multiComparator<A, B, C>(
  compA: SortStateMultiComparator<A>,
  compB: SortStateMultiComparator<B>,
  compC: SortStateMultiComparator<C>
): number;
export function multiComparator<A, B, C, D>(
  compA: SortStateMultiComparator<A>,
  compB: SortStateMultiComparator<B>,
  compC: SortStateMultiComparator<C>,
  compD: SortStateMultiComparator<D>
): number;
export function multiComparator<A, B, C, D, E>(
  compA: SortStateMultiComparator<A>,
  compB: SortStateMultiComparator<B>,
  compC: SortStateMultiComparator<C>,
  compD: SortStateMultiComparator<D>,
  compE: SortStateMultiComparator<E>
): number;
export function multiComparator(...comps: SortStateMultiComparator<unknown>[]): number;
export function multiComparator(...comps: SortStateMultiComparator<unknown>[]): number {
  if (!comps[0]) return 0;

  const [a, b, comparator] = comps[0];

  const result = comparator(a, b);
  if (!result) {
    const [, ...newComp] = comps;
    return multiComparator(...newComp);
  }

  return result;
}

export type MakeOrderedListComparatorOptions<T> = {
  fallbackComparator?: SortStateComparator<T>;
};

/**
 * Creates a comparator which orders objects in a list according to order
 * @param order The order in which to sort
 * @returns a comparator
 */
export const makeOrderedListComparator = <T>(
  order: readonly T[],
  options: MakeOrderedListComparatorOptions<T> = {}
): SortStateComparator<T> => {
  const { fallbackComparator = (a: T, b: T) => alphaComparator(`${a}`, `${b}`) } = options;

  const indexLookup = Object.values(order).reduce((lookup, item, i) => {
    lookup.set(item, i);
    return lookup;
  }, new Map<T, number>());

  return (a, b) => {
    const iA = indexLookup.get(a);
    const iB = indexLookup.get(b);

    if (iA === undefined && iB === undefined) return fallbackComparator(a, b);
    if (iA === undefined) return 1;
    if (iB === undefined) return -1;

    return numComparator(iA === undefined ? Infinity : iA, iB === undefined ? Infinity : iB);
  };
};

/**
 * Creates a comparator for sorting objects
 * @param key the key to sort on
 * @param comparator the comparator
 * @returns
 */
export const sortOnPropComparator =
  <T, D>(key: KeysByValue<T, D>, comparator: SortStateComparator<D>): SortStateComparator<T> =>
  (a, b) =>
    comparator(a[key as unknown as keyof T] as unknown as D, b[key as unknown as keyof T] as unknown as D);

/**
 * Creates a comparator for sorting objects by numeric keys
 * @param key the key to sort on
 * @returns
 */
export const sortOnNumPropComparator = <T>(key: KeysByValue<T, number>): SortStateComparator<T> =>
  sortOnPropComparator(key, numComparator);

/**
 * Creates a comparator for sorting objects by Date keys
 * @param key the key to sort on
 * @returns
 */
export const sortOnDatePropComparator = <T>(key: KeysByValue<T, Date | undefined | null>): SortStateComparator<T> =>
  sortOnPropComparator(key, dateComparator);
