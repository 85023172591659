import {
  Survey,
  SurveyAnswer,
  SurveyCompletedQuestion,
  SurveyQuestion,
  SurveyQuestionType,
  SurveyResponse,
} from "./SchedulingLinks";
import {
  Survey as SurveyDto,
  SurveyQuestion as SurveyQuestionDto,
  SurveyQuestionType as SurveyQuestionTypeDto,
  SurveyAnswer as SurveyAnswerDto,
  SurveyCompletedQuestion as SurveyCompletedQuestionDto,
  SurveyResponse as SurveyResponseDto,
} from "./scheduling-links-client";

//  Survey Questions

export const dtoToSurveyQuestion = (dto: SurveyQuestionDto): SurveyQuestion => ({
  questionValue: dto.questionValue as string,
  type: dto.type as SurveyQuestionType,
  isRequired: dto.isRequired as boolean,
});

export const dtoToSurvey = (dto: SurveyDto): Survey => ({
  questions: !!dto.questions?.length ? dto.questions.map(dtoToSurveyQuestion) : [],
});

export const surveyQuestionToDto = (surveyQuestion: SurveyQuestion): SurveyQuestionDto => ({
  ...surveyQuestion,
  questionValue: surveyQuestion.questionValue,
  type: surveyQuestion.type as SurveyQuestionTypeDto,
  isRequired: surveyQuestion.isRequired,
});

export const surveyToDto = (survey: Survey): SurveyDto => ({
  ...survey,
  questions: survey.questions.map(surveyQuestionToDto),
});

// Survey Answers / Response

export const dtoToSurveyAnswer = (dto: SurveyAnswerDto): SurveyAnswer => ({
  value: dto.value ? (dto.value as string) : undefined,
});

export const dtoToSurveyCompletedQuestion = (dto: SurveyCompletedQuestionDto): SurveyCompletedQuestion => ({
  question: dtoToSurveyQuestion(dto.question),
  answer: dtoToSurveyAnswer(dto.answer),
});

export const dtoToSurveyResponse = (dto: SurveyResponseDto): SurveyResponse => ({
  responses: dto.responses.map(dtoToSurveyCompletedQuestion),
});

export const surveyAnswerToDto = (surveyAnswer: SurveyAnswer): SurveyAnswerDto => ({
  ...surveyAnswer,
  value: surveyAnswer.value as string,
});

export const surveyCompletedQuestionToDto = (
  surveyCompletedQuestion: SurveyCompletedQuestion
): SurveyCompletedQuestionDto => ({
  ...surveyCompletedQuestion,
  question: surveyQuestionToDto(surveyCompletedQuestion.question),
  answer: surveyAnswerToDto(surveyCompletedQuestion.answer),
});

export const surveyResponseToDto = (surveyResponse: SurveyResponse): SurveyResponseDto => ({
  ...surveyResponse,
  responses: surveyResponse.responses.map(surveyCompletedQuestionToDto),
});
