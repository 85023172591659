// eslint-disable-next-line no-restricted-imports
import { ReclaimApiError } from "../reclaim-api/client";
import { AppNetworkError, AppReclaimApiError } from "../reclaim-api/clientPassthrough";
import {
  NetworkError as NetworkErrorSchedulingLinks,
  ReclaimApiError as ReclaimApiErrorSchedulingLinks,
} from "../reclaim-api/scheduling-links/scheduling-links-client";
// eslint-disable-next-line no-restricted-imports
import { NetworkError as NetworkErrorTeam, ReclaimApiError as ReclaimApiErrorTeam } from "../reclaim-api/team/client";
import { movePropsOver } from "./objects";

export const isReclaimApiError = <T>(err: T): T extends Error ? true : false =>
  (err instanceof AppReclaimApiError ||
    err instanceof ReclaimApiErrorTeam ||
    err instanceof ReclaimApiErrorSchedulingLinks) as T extends Error ? true : false;

export const isClientNetworkError = <T extends unknown>(err: T) =>
  (err instanceof AppNetworkError ||
    err instanceof NetworkErrorTeam ||
    err instanceof NetworkErrorSchedulingLinks) as T extends AppNetworkError ? true : false;

/**
 * Takes any of the ReclaimApiError classes from any of the three
 * client files and returns an identical one from the main client
 * file
 * @param err The error to normalize
 * @returns An identical error made from the class in the main client file
 */
export const normalizeReclaimApiError = <E extends { status: number; response?: unknown | null }>(
  err: ReclaimApiError<E> | ReclaimApiErrorSchedulingLinks<E> | ReclaimApiErrorTeam<E>
): AppReclaimApiError<E> => new AppReclaimApiError(err);

/**
 * Takes any of the NetworkError classes from any of the three
 * client files and returns an identical one from the main client
 * file
 * @param err The error to normalize
 * @returns An identical error made from the class in the main client file
 */
export const normalizeClientNetworkError = (
  err: AppNetworkError | NetworkErrorSchedulingLinks | NetworkErrorTeam
): AppNetworkError => {
  const out = new AppNetworkError(
    {
      // status is unused by NetworkError
      status: NaN,
      statusText: err.statusText || "",
      headers: err.headers,
      data: err.body,
      config: {},
    },
    err.message
  );

  movePropsOver(err, out);

  return out;
};
