/**
 * Auth
 */
export const LOCAL_STORAGE_AUTH_USER = "auth.user";
export const LOCAL_STORAGE_AUTH_REAUTH = "auth.reauth";
export const LOCAL_STORAGE_AUTH_ATTEMPTS = "auth.attempts";
export const LOCAL_STORAGE_AUTH_REDIRECT = "auth.redirect";
export const LOCAL_STORAGE_AUTH_REF = "auth.ref";
export const LOCAL_STORAGE_AUTH_UTM = "auth.utm";
export const LOCAL_STORAGE_AUTH_ADMIN = "auth.admin";
export const LOCAL_STORAGE_AUTH_IMPERSONATE = "auth.impersonate";
export const LOCAL_STORAGE_AUTH_IMPERSONATE_ERROR = "auth.impersonate.error";

/**
 * Quests
 */
export const LOCAL_STORAGE_ACTIVE_QUESTS = "Quests.active";

/**
 * Tasks
 */
export const LOCAL_STORAGE_TASKS_QUICK_ADD_SECTIONS = "Reclaim.Tasks.QuickAddSections";
export const LOCAL_STORAGE_TASKS_QUICK_ADD_DATA = "Reclaim.Tasks.QuickAddData";
export const LOCAL_STORAGE_TASKS_SPLIT_UP_CHUNKS = "Reclaim.Tasks.SplitUp";

/**
 * Priority
 */
export const LOCAL_STORAGE_PRIORITY_ACTIVE_ACCORDION = "Reclaim.Priority.ActiveAccordion";

/**
 * Planner
 */
export const LOCAL_STORAGE_PLANNER_SIDEBAR_TAB = "Planner.Sidebar.Tab";
