export function urlWithQueryParams(url: string, queryParams: Record<string, string | undefined>): string {
  const urlWithProtocol = url.startsWith("https://") || url.startsWith("http://") ? url : `https://${url}`;
  const patchedUrl = new URL(urlWithProtocol);
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      patchedUrl.searchParams.append(key, value);
    }
  });
  return patchedUrl.toString();
}

export const queryObjectToStrParams = <T extends string | number>(
  params: Record<string, T | undefined> | undefined
): string => {
  if (params === undefined) return "";

  const strQuery = Object.keys(params).reduce(
    (values, k) => (!!params[k] ? [...values, `${k}=${params[k]}`] : values),
    []
  );

  return strQuery.length ? "?" + strQuery.join("&") : "";
};
