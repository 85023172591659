import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS } from "date-fns/locale";
import { FC, useMemo } from "react";
import { useUserContext } from "src/context/UserContext";

export type ReclaimLocalizationProviderProps = {};

export const ReclaimLocalizationProvider: FC<ReclaimLocalizationProviderProps> = ({ children }) => {
  /********************/
  /*   custom hooks   */
  /********************/

  const [{ user }] = useUserContext();

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  const adapterLocale = useMemo<Locale>(
    () => ({ ...enUS, options: { weekStartsOn: user?.settings?.weekStart || 0 } }),
    [user?.settings?.weekStart]
  );

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
      {children}
    </LocalizationProvider>
  );
};
