import { atom, Atom, useAtom, useSetAtom, WritableAtom } from "jotai";
import { atomWithDefault, loadable } from "jotai/utils";
import { SetStateAction } from "jotai/vanilla";
import {
  Children,
  Fragment,
  isValidElement,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  RefCallback,
  useEffect,
  useMemo
} from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { useCaptureError } from "../hooks/useCaptureError";
import { LoadingErrorData } from "../reclaim-api/types";
import { JotaiAwaited, JotaiLoadable } from "../types/jotai";
import { findErrorMessage } from "./error";
import { awaitInterval } from "./promise";

export function retry(fn: () => Promise<{ default: React.ComponentType<unknown> }>, retries = 5, interval = 1000) {
  return new Promise<{ default: React.ComponentType<unknown> }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((err: unknown) => {
        setTimeout(() => {
          if (retries <= 1) return reject(err);
          retry(fn, interval, retries - 1).then(resolve, reject);
        }, interval);
      });
  });
}

export default {
  retry,
};

export function isTextInput(el: HTMLElement) {
  if ("TEXTAREA" === el.nodeName) return true;
  if (
    "INPUT" === el.nodeName &&
    !!el.hasAttribute &&
    el.hasAttribute("type") &&
    ["text", "email", "month", "number", "password", "search", "tel", "time", "url", "week"].includes(
      el.getAttribute("type") || ""
    )
  )
    return true;
  return false;
}

export function isAnchorElMissing(anchorEl) {
  const resolvedAnchorEl = typeof anchorEl === "function" ? anchorEl() : anchorEl;

  if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {
    const box = resolvedAnchorEl.getBoundingClientRect();

    if (process.env.NODE_ENV !== "test" && box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
      return true;
    }
  }
  return false;
}

/**
 * merges refs
 * @param refs refs to merge
 * @returns a singular function ref
 */
export function mergeRefs<T>(...refs: Ref<T>[]): RefCallback<T> {
  return (inst) => {
    refs.forEach((ref) => {
      if (!ref) return;
      switch (typeof ref) {
        case "function":
          ref(inst);
          break;
        default:
          (ref as MutableRefObject<T | null>).current = inst;
          break;
      }
    });
  };
}

export const hasChildren = (element: ReactNode): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);

/**
 * Maps through all children, deep mapping when a fragment is found
 * @param children The children to map over
 * @param cb The mapping callback
 * @param baseIterator Offsets the iterator number
 * @returns An array of ReactNodes
 */
export const deepMapChildrenWithFrags = (
  children: ReactNode,
  cb: (child: ReactNode, i: number) => ReactNode,
  baseIterator = 0
): ReactNode =>
  Children.map(children, (child) => {
    const i = baseIterator;
    if (!isValidElement(child)) {
      baseIterator++;
      return cb(child, i);
    }
    if (child.type === Fragment)
      return <>{deepMapChildrenWithFrags((child.props as { children: ReactNode }).children, cb, baseIterator)}</>;
    else {
      baseIterator++;
      return cb(child, i);
    }
  });

export type MakeLoadableHookReturnType<T, DATA_KEY extends string> = {
  useHook: () => LoadingErrorData<T, DATA_KEY>;
  dataAtom: WritableAtom<T, SetStateAction<T>, void>;
  loadableAtom: Atom<JotaiLoadable<T>>;
};

const useCaptureLoadableError = (
  loadableState: JotaiLoadable<unknown>,
  errorizerFn: (error: unknown) => Error
): Error | undefined => {
  const { captureError } = useCaptureError();

  const stateError = loadableState.state === "hasError" ? loadableState.error : undefined;
  let error = useMemo<Error | undefined>(
    () => (stateError ? errorizerFn(stateError) : undefined),
    [errorizerFn, stateError]
  );

  useEffect(() => {
    if (error)
      captureError(error, {
        severity: "error",
      });
  }, [captureError, error]);

  return error;
};

export interface MakeLoadableHookOptions<DATA_KEY extends string> {
  /**
   * The key under which data will be stored in the hook response
   */
  dataKey?: DATA_KEY;
  /**
   * A function or string used to generate errors
   */
  errorizer?: string | ((cause: unknown) => Error);
}

/**
 * Makes a hook which only loads the data from `getPromise` on first-use
 * @param getPromise A function which when called returns a promise which resolves to data
 * @param options Options for the hook
 * @returns A hook which returns a `LoadingErrorData`, as well as the atoms used internally
 */
export function makeLoadableHook<T, DATA_KEY extends string>(
  getPromise: () => Promise<T>,
  options: MakeLoadableHookOptions<DATA_KEY> = {}
): MakeLoadableHookReturnType<T, DATA_KEY> {
  const { dataKey = "data", errorizer = "Failed to load data" } = options;

  const dataAtom = atomWithDefault(getPromise);
  const loadableAtom = loadable(dataAtom) as Atom<JotaiLoadable<T>>;

  const errorizerFn =
    typeof errorizer === "string"
      ? (cause) =>
          new Error(errorizer, {
            cause: cause instanceof Error ? cause : new Error(findErrorMessage(cause)),
          })
      : errorizer;

  return {
    useHook: () => {
      const [loadableState] = useAtom(loadableAtom);
      const error = useCaptureLoadableError(loadableState, errorizerFn);

      return {
        [dataKey]: loadableState.state === "hasData" ? loadableState.data : undefined,
        loading: loadableState.state === "loading",
        error,
        state: loadableState.state,
      } as LoadingErrorData<T, DATA_KEY>;
    },
    dataAtom,
    loadableAtom,
  };
}

export interface MakeLoadableHookWithIntervalOptions<T, DATA_KEY extends string>
  extends MakeLoadableHookOptions<DATA_KEY> {
  /**
   * A hashing function used to determine if updates need to be triggered.  If no hasher is provided a simple `===` will be used.
   * @param obj The new incoming data from `getPromise`
   * @returns A hash
   */
  hasher?(obj: T): NonNullable<unknown>;
}

/**
 * Similar to `makeLoadableHook` but calls `getPromise` on an interval.  `getPromise` must complete before interval timer will start.
 * @param getPromise A function which when called returns a promise which resolves to data
 * @param ms The number of milliseconds between intervals
 * @param options Options for the hook
 * @returns A hook which returns a `LoadingErrorData`, as well as the atoms used internally
 */
export function makeLoadableHookWithInterval<T, DATA_KEY extends string>(
  getPromise: () => Promise<T>,
  ms: number,
  options: MakeLoadableHookWithIntervalOptions<T, DATA_KEY> = {}
): MakeLoadableHookReturnType<T, DATA_KEY> {
  const { hasher, ...makeLoadableHookOptions } = options;
  const { dataAtom, useHook: useHookInner, loadableAtom } = makeLoadableHook(getPromise, makeLoadableHookOptions);
  let lastHash: unknown;

  const intervalAtom = atom<{ stop: (() => void) | undefined; activeHooks: number }>({
    stop: undefined,
    activeHooks: 0,
  });

  const useHook = () => {
    const [intervalData, setIntervalData] = useAtom(intervalAtom);
    const setData = useSetAtom(dataAtom);
    const hookData = useHookInner();

    useEffect(() => {
      // in general: NEVER FLIPPING DO THIS!!!
      // Data in React is not super happy to
      // be mutable, here we need to do it so
      // that the data is immediately available
      // to all callers.
      //
      // Importantly: this data NEVER leaves the
      // scope of `makeLoadableHookWithInterval`
      // and is fully controlled from within
      // -SG
      intervalData.activeHooks++;

      if (!intervalData.stop) {
        intervalData.stop = awaitInterval(async () => {
          const newData = await getPromise();
          const newHash = hasher ? hasher(newData) : newData;

          if (newHash !== lastHash) {
            setData(newData);
            lastHash = newHash;
          }
        }, ms);
      }

      setIntervalData(intervalData);

      return () => {
        intervalData.activeHooks--;

        if (intervalData.activeHooks <= 0) {
          intervalData.stop?.();
          intervalData.stop = undefined;
        }

        setIntervalData(intervalData);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return hookData;
  };

  return { useHook, dataAtom, loadableAtom };
}

export type MakeReloadableHookReturnType<T, DATA_KEY extends string> = {
  useHook: () => LoadingErrorData<T, DATA_KEY> & { reload: () => Promise<void> };
  dataAtom: WritableAtom<T | null, SetStateAction<T>, void>;
};

export function makeReloadableHook<T, DATA_KEY extends string>(
  getPromise: () => Promise<T>,
  options: MakeLoadableHookOptions<DATA_KEY> = {}
): MakeReloadableHookReturnType<T, DATA_KEY> {
  const { dataKey = "data", errorizer = "Failed to load data" } = options;

  const sourceAtom = atom<JotaiLoadable<T>>({ state: "loading" });
  const dataAtom = atom(
    (get) => {
      const src = get(sourceAtom);
      return src.state === "hasData" ? src.data : null;
    },
    (_get, set, data: T) => {
      set(sourceAtom, { state: "hasData", data: data as JotaiAwaited<T> });
    }
  );

  sourceAtom.onMount = (set) => {
    void getPromise().then(
      (data) => {
        set({ state: "hasData", data: data as JotaiAwaited<T> });
      },
      (error) => {
        set({ state: "hasError", error });
      }
    );
  };

  const errorizerFn =
    typeof errorizer === "string"
      ? (cause) =>
          new Error(errorizer, {
            cause: cause instanceof Error ? cause : new Error(findErrorMessage(cause)),
          })
      : errorizer;

  return {
    useHook: () => {
      const [loadableState, setLoadableState] = useAtom(sourceAtom);
      const error = useCaptureLoadableError(loadableState, errorizerFn);

      const reload = useCallbackSafeRef(async () => {
        await setLoadableState({ state: "loading" });

        try {
          const data = await getPromise();
          setLoadableState({ state: "hasData", data: data as JotaiAwaited<T> });
        } catch (error) {
          setLoadableState({ state: "hasError", error });
        }
      });

      return {
        [dataKey]: loadableState.state === "hasData" ? loadableState.data : undefined,
        loading: loadableState.state === "loading",
        error,
        state: loadableState.state,
        reload,
      } as LoadingErrorData<T, DATA_KEY> & { reload: () => Promise<void> };
    },
    dataAtom,
  };
}
